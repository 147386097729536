/*
* Ray-Ban Skeleton V1.0
* Sapient 2013, Ravi Kumar
*/

/* Site Styles */

@import "../common/variables";

/// Set internal color variables
$return-title: get-color("grey-3") !default;
$login-border-color: get-color("grey-5") !default; // dfdfdf
$success-green-color: $green !default;

/* login register styles
====================================================*/

.wcs-login-title.com {
  color: get-color("greyLighten2");
}

.margin-top-container {
  margin-top: 38px;
}

.register-container {
  width: 416px;
}

h1.wcs-login-title {
  color: $black;
  font-size: 32px;
  line-height: 51px;
  font-family: $font-bold;
  font-weight: $font-weight-oswald;
  margin: 0 0 24px;
  text-transform: uppercase;
}

.address_subtitle {
  font-family: $font-family;
  line-height: 1.58;
  font-size: 12px;
  font-weight: 300;
  color: $primary-color;
  margin-top: 11px;
}

.wcs-details-addNewAddress {
  display: none !important;
}

.single_address {
  width: 620px !important;
  padding: 0 !important;
  float: right !important;
  h4 {
    float: left;
    margin-left: 20px;
    margin-right: 20px;
    width: 150px !important;
  }
  .wcs-details-address-book {
    font-family: $font-family !important;
    color: $primary-color !important;
    font-size: 13px !important;
    margin-top: 22px;
    width: 150px;
    float: left;
    span {
      margin-top: 4px;
    }
  }
  .wcs-text-align-right {
    float: right;
    margin: 15px 20px 0 0;
  }
  .address_delete {
    margin-right: 10px;
    font-family: $font-bold;
    font-weight: $font-weight-oswald;
    text-transform: uppercase;
    font-size: 14px;
  }
}

.single_address:last-of-type {
  margin-bottom: 50px;
}

.wcs-details-delivery-address {
  width: 920px;
}

.add_new_address {
  background: transparent;
  z-index: 0;
  position: static;
  float: left;
  margin-bottom: 50px;
  .nickname {
    margin-top: 30px;
    color: $primary-color;
    text-transform: uppercase;
    font-family: $font-bold;
    font-weight: $font-weight-oswald;
  }
}

.back_addressbook {
  font-size: 14px;
  font-family: $font-bold;
  font-weight: $font-weight-oswald;
  text-transform: uppercase;
  cursor: pointer;
  display: none;
  i {
    margin-top: -3px;
    margin-left: -6px;
  }
  position: relative;
  left: -7px;
  top: -1px;
}

.wcs-register-subtitle {
  font-family: $font-regular;
  line-height: 1.58;
  font-size: 13px;
  font-weight: 300;
  color: $primary-color;
  p {
    margin-bottom: 24px;
  }
  p:last-child {
    margin-bottom: 0;
  }
  &--center {
    text-align: center;
  }
}

.wcs-user-register p,
.wcs-user-login p,
.wcs-guest-checkout-container p {
  font-family: $font-regular;
  line-height: 1.5;
  font-size: 12px;
  font-weight: 300;
  color: $primary-color;
}

.wcs-register-with-us p {
  font-size: 12px;
  line-height: 16px;
  color: get-color("greyLighten2");
  margin-bottom: 3px;
}

.wcs-user-login .forgot_password {
  color: $greyLighten2;
  float: right;
  font-family: $font-regular;
  font-size: 13px;
  margin: 10px 0;
}

.wcs-user-login .forgot_password a {
  text-decoration: underline;
  font-size: 14px;
  line-height: 22px;
}

.wcs-user-login {
  &.checkout-login-section {
    form {
      .wcs-row {
        color: get-color("grey-3");
      }
    }
  }
  .wcs-register-subtitle {
    margin-bottom: 24px;
  }
}

.btn-red {
  border: 2px solid get-color("red");
  color: get-color("red");
}

.wcs-email-text {
  margin-left: 20px;
}

#createAccount {
  margin: 30px 0 50px;
}

.notorjustamember {
  font-size: 14px;
  font-family: $font-regular;
  margin-bottom: 70px;
  margin-top: 40px;
  line-height: 22px;
  text-align: center;

  & span {
    color: $primary-color;
  }
  & a {
    text-transform: uppercase;
    color: $greyLighten2;
  }
}

.wcs-user-register {
  .notorjustamember {
    margin-top: 0;
  }
  #createAccount {
    margin-bottom: 40px;
  }
}

#forgotPassword_submit {
  margin: 30px 0 70px;
}

.wcs-dob-validate:after {
  content: "";
  display: table;
  clear: both;
}

.wcs-date-of-birth-text {
  clear: both;
  margin-bottom: 10px;
}

.wcs-disclaimer-box {
  margin-top: 20px;
  width: 390px;
}

.wcs-small-letter {
  font-size: 11px !important;
}

a.wcs-guest-checkout-link,
a.wcs-create-account-link {
  display: inline-block;
  padding-top: 10px;
}

.wcs-back-style #deliveryBack {
  position: relative;
  margin-left: 8px;
}

.wcs-back-style .wcs-icon-left {
  margin-right: 24px;
  margin-top: -4px;
}

/* delivery information styles
====================================================*/

.wcs-delivery-shopping-cart-accordian > div.wcs_shopping_items .scroller > div,
.wcs-delivery-shopping-cart-accordian > div.wcs_hometry_items .scroller > div {
  position: relative;
  margin: 10px 17px 10px 0;
  padding: 5px 0 10px;
  border-top: 1px solid $login-border-color;
  width: 280px;
}

.wcs-delivery-shopping-cart-accordian > div.wcs_shopping_items .scroller > div {
  width: 250px;
}

.wcs-delivery-shopping-cart-accordian > div.wcs_shopping_items .scroller > div .shopbag-junior-icon,
.wcs-delivery-shopping-cart-accordian > div.wcs_hometry_items .scroller > div .shopbag-junior-icon {
  position: absolute;
  top: 1px;
  left: 0;
  width: 74px;
  height: 23px;
  background: $sprite-desktop no-repeat 0 -824px;
  cursor: default;
  display: inline-block;
}

.wcs-delivery-shopping-cart-accordian > div.wcs_shopping_items .scroller > div:first-child,
.wcs-delivery-shopping-cart-accordian > div.wcs_hometry_items .scroller > div:first-child {
  border-top: 0;
}

.wcs-delivery-shopping-cart-accordian > div.wcs_shopping_items .scroller > div:last-child .checkout_sr_cartProductDiv,
.wcs-delivery-shopping-cart-accordian > div.wcs_hometry_items .scroller > div:last-child .checkout_sr_cartProductDiv {
  margin-bottom: 10px;
}

.wcs-delivery-shopping-cart-accordian h5 {
  padding: 10px 0;
  border-bottom: 0;
  border-top: 1px solid $login-border-color;
  cursor: pointer;
  text-transform: none;
  color: get-color("grey-3");
}

.wcs-delivery-shopping-cart-accordian img {
  display: block;
  margin: 0 auto;
}
.returnSummaryImageContainer img {
  width: 100%;
}

.wcs-delivery-shopping-cart-accordian .wcs-product-description {
  text-transform: uppercase;
}

.wcs-delivery-shopping-cart-accordian .checkout_sr_cartProductDiv {
  margin-top: 7px;
}

.wcs-shopping-total-container {
  background-color: get-color("ligthGrey");
  padding: 10px 0;
  overflow: hidden;
  margin-bottom: 50px;
}

#checkout-summary-container .wcs-shopping-total-container {
  margin-bottom: 20px !important;
  position: relative;
}

#checkout-summary-container .wcs-delivery-details {
  // LXB20R-1979 start
  .wcs-delivery-to {
    margin-bottom: 16px;
    padding-bottom: 18px;
    border-bottom: 1px solid #e2e2e5;
    h4 {
      margin-top: 0;
      text-transform: none;
      &:first-letter {
        text-transform: uppercase;
      }
    }
  }
  .wcs-billing-to {
    h4 {
      text-transform: none;
      &:first-letter {
        text-transform: uppercase;
      }
    }
  }
  // LXB20R-1979 end
}

.wcs-product-description .wcs-product-name,
.wcs-product-description .wcs-price {
  color: get-color("grey-3");
}

.wcs-sidebar-desc-bottom > strong {
  line-height: 17px;
  margin-top: 3px;
}

.wcs-sidebar-desc-bottom span {
  margin-top: 3px;
  line-height: 18px;
}

.wcs-shopping-total-container span {
  text-align: right;
  display: block;
  word-break: break-all;
}

.payment-period {
  span {
    padding-right: 1.313rem;
  }
}

.delivery-section {
  .payment-period {
    display: none;
  }
}

.wcs-delivery-cost {
  font-size: 1em;
}

.wcs-delivery-total {
  border-top: 2px solid get-color("grey");
  border-bottom: 2px solid get-color("grey");
  color: get-color("black");
  margin: 10px;
  padding: 16px 0;
  font-size: 15px;
  text-transform: uppercase;
  #rb-pay-over-time-container {
    .rb-modal-container { 
      width: 100%;
      text-transform: none;
    }
  }
  // LXRB20R-2035 start
  .rb-overlay {
    opacity: 1;
    z-index: 10;
  }
  
}

.wcs-delivery-total span {
  width: 105px;
  display: inline-block;
  text-align: right;
  line-height: 1.38;
}

.wcs-delivery-total span:first-child:not(.common__icon--close-bold, .rb-pay-over-time__message) {
  width: 140px;
  & .common__icon.common__icon--delete {
    width: 24px;
    height: 24px;
  }
}

.afterpay__block {
  .afterpay-content span {
    width: auto !important;
    text-transform: none;
    text-align: start;
  }
}

.wcs-delivery-total span.wcs-width-auto {
  width: auto;
}

.payment-mode {
  margin: 0 0 10px;
  padding: 15px;
  overflow: hidden;
  background: get-color("ligthGrey");
}

.gift-card {
  border-top: 1px solid get-color("ligthGrey");
  padding: 20px 0 0;
  margin: 15px 0 0;
  overflow: hidden;
}

.pay_with_giftCard .delivery-information-note {
  margin-bottom: 10px;
  border-bottom: solid 1px get-color("grey");
  padding-bottom: 20px;
  margin-top: -10px;
}

.pay_with_giftCard .delivery-information-note.com {
  border-bottom: 0;
}

.wcs-marTopnote-2 {
  margin-top: -2px !important;
}

/*delivery form requirement*/

input#next:enabled {
  background-color: get-color("black");
  color: get-color("white");
}

#next.wcs-btn-disable {
  color: get-color("white") !important;
  border: 2px solid get-color("black") !important;
}

.wcs-billing-address {
  font-family: $font-regular;
  font-size: 13px;
}

.wcs-register-terms-conditions {
  font-family: $font-family;
  font-size: 11px;
  font-weight: 300;
  line-height: 1.86;
}

/* payment information styles
===========================================*/

.wcs-tel-code {
  width: 75px;
  float: left;
}

.wcs-tel-number {
  width: 136px;
  float: right;
  margin-right: 1px;
}

.wcs-card-number {
  margin-right: 7px;
}

.wcs-billing-address,
.wcs-contact-address {
  margin: 15px 0 25px;
}

.wcs-container p {
  color: $primary-color;
  font-size: 14px;
  line-height: 22px;
}

.wcs-container .wcs-doublehalf-bottom {
  font-weight: 600;
}

.wcs-empty-text-favourite p {
  font-family: $font-bold;
  font-weight: $font-weight-oswald;
}

.wcs-payPal-button {
  background: $sprite-desktop repeat scroll -298px 0 !important;
  border: medium none !important;
  color: get-color("black");
  cursor: pointer;
  height: 32px;
  width: 143px;
}

.applied_gift_card .wcs-remove {
  font-size: 13px;
}

/* form validation error styles
===========================================*/

.wcs-error-new-customer {
  margin: 0 0 20px 155px;
  width: 217px;
  margin-left: 150px;
}

.wcs-error-returning-customer {
  margin: 0 0 20px 155px;
  width: 218px;
}

.wcs-error-register-us {
  margin: 0 0 20px 157px;
  width: 221px;
}

.applied_gift_card {
  clear: both;
  padding-top: 5px;
}

.applied_gift_card .wcs-two {
  width: 140px;
}

.applied_gift_card .wcs-three {
  width: 225px;
}

.applied_gift_card a.wcs-remove:hover {
  color: $primary-color !important;
}

.wcs-marTop-3 {
  margin-top: 3px !important;
}

.wcs-gift-card-number,
.wcs-gift-card-value,
.wcs-gift-card-balance {
  display: block;
  font: 12px $font-regular;
}

.wcs-gift-card-number {
  text-transform: uppercase;
}

.applied_gift_card .wcs-icon {
  right: 7px;
  top: 0;
}

.agreeConsent a {
  color: get-color("grey-3");
}

.wcs-delivery-info-textarea {
  resize: none;
  box-sizing: border-box;
  height: 140px;
}

#delivery_information_form .wcs-borderTopGrayThick {
  color: get-color("greyLighten2");
}

.wcs-shopping-help-box {
  background: none repeat scroll 0 0 get-color("greyLighten2");
  color: get-color("white");
  font-size: 16px;
  margin-bottom: 30px;
  padding: 12px 10px 8px 10px;
  position: relative;
  text-transform: uppercase;
}

.wcs-shopping-help-box .wcs-icon-telephone {
  background-position: -56px -211px;
  cursor: auto;
  left: 10px;
  top: 9px;
}

.wcs-shopping-help-box .wcs-padLeft-25 {
  padding-left: 30px !important;
}

.wcs-shopping-help-box .wcs-half-left {
  margin-left: 0 !important;
}

.wcs-shopping-panel-title {
  min-height: 30px;
  line-height: 30px;
}

.wcs-shopping-panel-title a {
  color: get-color("greyLighten2");
}

.wcs-paypal-icon {
  background-position: -295px -6px;
  height: 27px;
  left: 23px;
  top: -4px;
  width: 55px;
}

.wcs-alipay-icon {
  background-position: -513px -113px;
  width: 60px;
  height: 38px;
  left: 23px;
  top: -10px;
}

.wcs-unionpay-icon {
  background-position: -513px -163px;
  width: 60px;
  height: 38px;
  left: 23px;
  top: -10px;
}

.wcs-secure-payment {
  left: 0;
  background-position: -104px -153px;
}

.wcs-card-verification-icon {
  background-position: -85px -174px;
  height: 30px;
  width: 50px;
  top: 9px;
  left: 6px;
}

.wcs-card-verification-dark-icon {
  background-position: -86px -213px;
}

.gift_apply {
  float: left;
  font-size: 16px; /// @todo check sass syntax
  /*-bracket-: hack(;
  margin-top: 0;
  /*For Chrome Browser*/
  //);
  padding-left: 10px;
  text-transform: uppercase;
}

.wcs-delivery-shopping-cart-accordian .wcs_shopping_items,
.wcs-delivery-shopping-cart-accordian .wcs_hometry_items {
  position: relative;
}

.wcs-shopping-scroller {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  width: 312px;
  overflow: auto;
}

#singlePageCheckoutContainer .wcs-shopping-scroller {
  width: 270px;
}

.ie8 .wcs-shopping-scroller {
  overflow-y: auto !important;
  overflow-x: hidden !important;
}

/* common form style
===========================================*/

.wcs-login-title h4,
.personalize-title {
  font-size: 18px;
  font-family: inherit;
  color: $primary-color;
}

#delivery_information_form .wcs-billing-address {
  font-family: inherit;
  color: $primary-color;
  padding: 0;
  p {
    font-size: 13px;
    line-height: 1.5;
  }
}

#form-wrapper .wcs-delivery-information-title #delivery_information_form .wcs-return-delivery-button-right {
  margin-top: 25px !important;
}
#form-wrapper .wcs-delivery-information-title #chkout_summary .wcs-shopping-total-container .wcs-delivery-total-cost {
  .wcs-delivery-subtotal {
    width: 175px !important;
    padding-left: 10px;
    span {
      text-align: left;
    }
  }
}
#form-wrapper
  .wcs-delivery-information-title
  #chkout_summary
  .wcs-shopping-total-container
  .wcs-delivery-total
  .wcs-delivery-total-text {
  width: 141px !important;
  margin-left: 0 !important;
  text-align: left !important;
}

.wcs-delivery-note {
  font-family: $font-regular;
  font-size: 12px;
  padding: 35px 0 5px;
}

.wcs-width-363.wcs-width-371 {
  width: 371px !important;
}

.wcs-login-register {
  .checkout-login .wcs-login-title {
    padding: 8px 24px !important;
  }

  h4.wcs-gift-card-delivery-title {
    padding-top: 18px;
  }

  .wcs-disclaimer-text + .exclamatory {
    float: left !important;
    top: 1px !important;
    margin: 0 0 0 -10px;
  }
}

.wcs-delivery-information {
  .delivery-edit-address,
  .delivery-new-address {
    display: none;
    font-size: $medium-font-size;
    border: 0;
    text-transform: none;
    padding: $space-1 * 5 0;
    margin: 0;
  }

  .wcs-delivery-information__disclaimer {
    margin: 20px 0;

    p {
      font-size: 12px;
      line-height: 20px;

      a {
        text-decoration: underline;
      }
    }
  }
}

.wcs-details-delivery-addresses {
  .selected {
    .edit-btn.invisible {
      visibility: hidden !important;
    }
  }
}

.wcs-container .wcs-custom-user-order p {
  font-family: $font-regular;
  line-height: 18px;
  font-size: 12px;
  font-weight: 300;
  color: $primary-color;
  margin-top: 15px;
  text-align: center;
  a {
    color: $primary-color;
    text-decoration: underline;
  }
}
.wcs-container .track-orders .wcs-custom-user-order p {
  br {
    content: "";
    display: block;
    margin-top: 10px;
  }
}
.wcs-container .wcs-custom-user-order p a.trackorder-mail {
  color: #666666;
  display: block;
  text-decoration: underline;
}

.wcs-container .track-orders .wcs-custom-user-order p.wcs-custom-user-order-additional {
  br {
    content: "";
    display: inline;
    margin: 0;
  }
  a {
    display: block;
  }
}

.wcs-custom-user-order.wcs-user-login .forgot_password a {
  color: get-color("greyLighten2");
}

#login_submit {
  margin: 30px auto 15px;
  border-bottom-width: 0px;
  border-right-width: 0px;
  border-left-width: 0px;
  border-top-width: 0px;
  display: flex;
  font-family: $font-bold;
  min-width: 209px;
}

#login_submit_order {
  margin: 30px auto 0;
  display: block;
}

.order_block_footer {
  margin: 40px 0 70px;
  p {
    margin: 0 !important;
    line-height: 1.5 !important;
    br {
      margin: 0 !important;
    }
  }
}

#forgot_password_div .title {
  font-weight: 600;
}

.wcs-forget-password-elements {
  margin-bottom: 50px;
}

.wcs-custom-user-order.wcs-user-login .forgot_password a:visited {
  color: get-color("grey-3");
}

.wcs-custom-user-order form {
  margin-top: 22px;
}

.wcs-user-login #wcs-track-order .wcs-order-create-account {
  padding-top: 10px;
}

#wcs-track-order {
  @include rb-fieldrow-custom;
}

.wcs-gift-card-delivery-form {
  padding-top: 18px;
  clear: both;
}

.wcs-delivery-note-title {
  padding-top: 20px !important;
}

.required .wcs-font-12 {
  font-size: 12px !important;
}

/* for checkout return policy */

.wcs-returns-inner {
  padding: 80px 95px;
}

#checkout_returns_content {
  /*width:750px;*/
  height: 450px;
}

.ie8 #checkout_returns_content {
  overflow-y: auto !important;
  overflow-x: hidden !important;
}

#checkout_returns_div p {
  margin-bottom: 15px;
  line-height: 21px;
}

.wcs-heading1 {
  font-size: 35px;
  margin-bottom: 40px;
  font-weight: bold;
  text-transform: uppercase;
  color: get-color("grey-3");
}

.wcs-heading2 {
  font-size: 25px;
  margin: 30px 0 20px 0;
  font-weight: bold;
  text-transform: uppercase;
  color: get-color("grey-3");
}

.wcs-returns-inner h1,
.wcs-returns-inner h2 {
  color: $return-title;
}

.wcs-returns-inner h1 {
  font-size: 38px;
}

.wcs-returns-inner h2 {
  font-size: 22px;
}

.wcs-delivery-note-title {
  padding-top: 20px !important;
}

.wcs-font-12 {
  font-size: 12px !important;
}

.wcs-excl-tax {
  display: inline !important;
  text-transform: none !important;
}

.wcs-shopping-bag-free {
  font-size: 13px !important;
}

.wcs-custom-dropdown-padding-left li {
  padding-left: 7px !important;
  padding-top: 13px !important;
}

.wcs-custom-dropdown li:hover {
  color: get-color("grey-3") !important;
}

.wcs-select-existing-add {
  color: get-color("grey-3") !important;
}

.wcs-shopping-panel-title a:hover {
  color: get-color("grey-3") !important;
}

.wcs-delivery-total-cost {
  margin: 0 10px 21px !important;
}

.wcs-delivery-subtotal {
  width: 195px !important;
  margin: 6px 0 0 0 !important;
}

.wcs-delivery-subtotal-price {
  width: 83px !important;
  margin: 6px 0 0 0 !important;
}

.wcs-delivery-total-text {
  width: 130px !important;
  margin-left: 20px !important;
}

.return-summary {
  .wcs-delivery-subtotal-price,
  .wcs-delivery-subtotal {
    span {
      padding: 10px;
    }
  }
  .wcs-shopping-total-tax {
    font-size: 14px;
  }

  .wcs-delivery-total-price-return,
  .wcs-delivery-total-text-return {
    width: 100% !important;
    text-align: right !important;
    font-family: $font-bold;
    font-weight: $font-weight-oswald;
    padding-right: 10px;
  }
  .wcs-delivery-total-text-return {
    margin-bottom: 20px;
    word-break: normal;
  }
}

.return-summary,
.return-payment {
  .wcs-delivery-total-text {
    width: 100% !important;
    margin-bottom: 20px;
    word-break: normal;
  }

  .wcs-product-case {
    text-transform: none;
    padding-bottom: 10px;
  }

  .RoxRemix-label-mini-basket {
    margin-top: -20px;
  }

  .scroller-item {
    display: flex;
    padding: 15px 20px 10px 5px;

    a {
      width: 100px;
      display: flex;
      flex-direction: column;
      top: -10px;
    }
  }

  .wcs-product-name span,
  .wcs-product-no {
    padding-bottom: 10px;
  }

  .wcs-shopping-item-image {
    padding-bottom: 20px;
  }

  .wcs-main-image,
  .wcs-shopping-item-image {
    width: 100px;
    height: auto;
  }

  .wcs-product-description {
    width: 167px;
    padding-right: 20px;
  }

  .wcs-product-name,
  .quantity,
  .wcs-delivery-total-amount {
    font-family: $font-bold;
    font-weight: $font-weight-oswald;
  }
  .quantity {
    text-transform: capitalize;
  }
  .wcs-delivery-shopping-cart-accordian {
    .wcs-pos-relative {
      font-family: $font-bold;
      font-weight: $font-weight-oswald;
      font-size: 16px;
      margin: 0;
    }
  }
  .wcs-shopping-panel-title .wcs-two.wcs-columns.wcs-omega {
    width: 230px;
    float: right;
    font-family: $font-bold;
    font-weight: $font-weight-oswald;
  }
}

.wcs-delivery-total-amount {
  margin: 20px 20px 11px 20px;
}

.wcs-width-137 {
  width: 137px !important;
}

.wcs-width-139 {
  width: 139px !important;
}

.wcs-secured-paymentpayment-mode {
  padding: 12px 15px 11px 6px !important;
}

.wcs-credit-card-notification-box {
  padding: 13px 11px 13px 15px !important;
  font-size: 11px !important;
}

.wcs-dropdown-cardtype li {
  color: get-color("grey-3") !important;
}

.wcs-custom-dropdown-select-cardtype {
  color: get-color("grey-3") !important;
}

.wcs-custom-dropdown-select-cardtype li {
  color: get-color("grey-3") !important;
}

.wcs-width-363.wcs-width-372 {
  width: 372px !important;
}

.wcs-my-selections-button {
  line-height: 18px !important;
  margin-top: 3px !important;
  padding: 5px 7px !important;

  &.wcs-button-grey {
    border: 2px solid get-color("greyLighten2");

    a {
      color: get-color("greyLighten2");
    }
  }
}

.wcs-paddingTop-9 {
  padding-top: 9px !important;
}

.wcs-change-email-button {
  padding: 5px 7px !important;
}

.wcs-delivery-information input[type="text"],
.wcs-delivery-information input[type="email"],
.wcs-delivery-information select {
  display: inline-block;
}

.wcs-delivery-information input[name="country_roe"] {
  border-bottom: 0;
  padding-left: 0;
}

.wcs-delivery-information .wcs-dropdown-text {
  color: get-color("grey-3") !important;
}

.wcs-add-address {
  float: left;
  left: 361px;
  margin-top: 4px;
  position: absolute;
}

.wcs-modified-payment .wcs-secured-paymentpayment-mode {
  padding: 15px 15px 15px 6px !important;
}

#payment_mode {
  overflow: hidden;
}

#singlePageCheckoutContainer #payment_mode {
  overflow: visible;
}

.wcs-modified-payment .secured-payment-label {
  line-height: 1 !important;
}

// LXRB20R-2254 START
#payment_mode_form {
  .secured-payment-label {
    font-family: $font-family;
    font-size: 16px;
    line-height: 1.38;
    color: $primary-color;
    text-transform: uppercase;
    margin-bottom: 30px;
  }
}

.wcs-padLeft-32 {
  padding-left: 32px;
}

.wcs-modified-payment .wcs-secure-payment {
  left: 10px;
  top: 2px;
}

.wcs-width-130 {
  width: 130px;
}

.wcs-marTop-3 {
  margin-top: 3px;
}

.wcs-color-888 {
  color: get-color("greyLighten2");
}

.wcs-marRt-0 {
  margin-right: 0 !important;
}

.wcs-modified-shopcart .wcs-delivery-subtotal,
.wcs-modified-shopcart .wcs-delivery-subtotal-price {
  margin-top: 10px !important;
}

.wcs-modified-shopcart .wcs-delivery-total-amount {
  margin-top: 11px;
  text-align: right;
}

.gift-card-total {
  margin-left: 5px;
  margin-right: 20px;
}

.wcs-one-and-half.wcs-columns.wcs-alpha.wcs-omega.gift-card-total-price {
  display: flex;
  flex-flow: column wrap;
}

.wcs-padLeft-30 {
  padding-left: 30px;
  margin-top: 3px;
}

.wcs-checkout-tick {
  display: inline-block;
  background: $sprite-desktop no-repeat -308px -260px;
  width: 10px;
  height: 10px;
  margin-right: 6px;
}

body .checkout-header-menu {
  margin-left: 43px !important;
}

body .wcs-first-checkout-link {
  margin-left: 33px !important;
}

.wcs-checkout-agree {
  background: get-color("ligthGrey");
  padding: 20px;
  clear: both;
}

.wcs-agree-tooltip.pay_pal_radio_tooltip {
  padding: 10px 14px;
  border: 1px solid get-color("ligthGrey");
  font-family: $font-regular;
  font-size: 12px;
  width: 166px;
  position: absolute;
  top: -155px;
  left: 0;
  background: get-color("ligthGrey");
  color: get-color("greyLighten2");
}

.wcs-agree-tooltip.credit_card_radio_tooltip {
  padding: 10px 14px;
  border: 1px solid get-color("ligthGrey");
  font-family: $font-regular;
  font-size: 12px;
  width: 166px;
  position: absolute;
  top: -156px;
  left: 0;
  background: get-color("ligthGrey");
  color: get-color("greyLighten2");
}

.tooltip-Wrapper {
  position: relative;
  top: 0;
  float: right;
}

.wcs-payment-confirm-buttons-div {
  display: table;
  width: 100%;
  clear: both;
}

#payment_mode_form .rb-new-giftposition {
  margin: 10px 0 !important;
  width: auto;
  .giftCardLinks {
    display: block;
  }
  .giftcard_pin_number {
    float: none;
    min-width: 196px;
    margin-right: 6px;
  }
  .pin_1 {
    width: 100%;
  }
  .redeemBtn a {
    padding: 0 21px;
  }
  input:not(:placeholder-shown) + label {
    font-size: 75%;
    transform: translate3d(0, -100%, 0);
    opacity: 1;
  }
  input:not(::-ms-input-placeholder) + label {
    font-size: 75%;
    opacity: 1;
    color: $primary-color;
    transform: translateY(-100%);
  }
  input:not(:-ms-input-placeholder) + label {
    font-size: 75%;
    opacity: 1;
    color: $primary-color;
    transform: translateY(-100%);
  }
  .gift-card {
    position: static;
    min-width: 560px;
  }
}

.wcs-agree-tooltiparrow {
  position: absolute;
  bottom: -8px;
  left: 15px;
  background: $sprite-desktop no-repeat -355px -260px;
  width: 14px;
  height: 8px;
}

.wcs-checkout-current {
  background: $sprite-desktop no-repeat -194px -160px;
  width: 10px;
  height: 5px;
  display: block;
  clear: both;
  margin: 0 auto;
  position: relative;
  bottom: -21px;
  z-index: 1;
}

.wcs-delivery-shopping-cart-accordian > div.wcs_shopping_items .wcs-shopping-scroller-no-scroll .scroller > div {
  margin-right: 0;
  width: 279px;
}

#singlePageCheckoutContainer
  .wcs-delivery-shopping-cart-accordian
  > div.wcs_shopping_items
  .wcs-shopping-scroller-no-scroll
  .scroller
  > div {
  width: 250px;
}

input.giftcard_pin_number {
  width: 105px \9;
}

.ie8 #WC_PaymentInfoForm_creditCardNumber_1 {
  width: 220px !important;
}

.wcs-category-listing.wcs-main .wcs-create-account-container {
  padding-top: 3px !important;
}

/*--My account details Email signup --*/

.wcs-myDetails-email-signup {
  clear: left;
  // padding-left: 120px;
  width: 392px;
  float: left;
}

.wcs-myDetails-rightArea {
  display: flex;
  flex-direction: column;
}

.wcs-account-editDetails-button {
  position: relative;
  text-align: right;
  padding-right: 200px;
}

.wcs-myPreferences .wcs-account-editDetails-button {
  top: 0;
}

.wcs-myOrder-delivery-email-signup {
  margin-top: 20px;
}

.wcs-myDetails-edit-email-signup {
  margin-left: 0;
}

.wcs-myDetails-edit-save-cancel {
  clear: both;
  left: 638px;
  position: relative;
  margin-top: -70px;
}

.wcs-en_US .wcs-open-orders .wcs-order-amount span.wcs-order-amount-span {
  width: auto;
}

.wcs-paymode {
  width: 208px;
  margin: 5px;
  padding: 3px;
  border: 1px solid $login-border-color;
  background: get-color("white");
  border-radius: 3px;
}

.wcs-paymode .wcs-label-radio {
  height: 40px;
}

.wcs-paymode .wcs-icon {
  position: static;
}

.wcs-paymode label.wcs-label-radio span.wcs-radiobutton {
  top: 12px;
}

.wcs-print-preview-wrapper .wcs-font-family {
  font-family: $font-bold;
  font-weight: $font-weight-oswald;
}

#wcs-print_UPS_label iframe {
  height: 900px;
}

.wcs-myOrder-details-content .wcs-myOrder-rows .wcs-two.wcs-columns.wcs-omega .wcs-shopping-item-price {
  padding-left: 0;
  padding-right: 0;
  width: 96px;
  text-align: right;
  margin-top: 20%;
}

body #payment_mode.wcs-modified-payment .wcs-secured-paymentpayment-mode {
  padding: 0 0 0 0 !important;
  box-sizing: border-box;
  min-height: 64px;
  border: solid 1px #e2e2e5;
  .wcs-label-radio {
  }
  .rb-fieldrow {
    width: 474px;
  }
  .cc-cvv-wrapper {
    width: 474px;
  }

  .payment-description--paypal {
    img {
      height: 19px;
    }
  }
  .payment-description--klarna {
    img {
      width: 62px;
      height: 22px;
    }
    #wcs-klarna-popup-link {
    }
  }

  #wcs-afterpay-popup-link {
     margin-bottom: 4px;
  }
  #pay-pal-radio {
    margin-right: 6px;
  }
  #klarna-radio {
  }
}

.wcs-exclusive-offers-link.wcs-msg-success {
  border: 2px solid $success-green-color;
  color: $success-green-color;
  margin: 30px 0;
}

.wcs-exclusive-offers-link.wcs-msg-success a {
  color: $success-green-color;
  text-decoration: none;
}

/* #Media Queries
================================================== */

/// These are base media queries. Uncomment if need to use them.
// /* Smaller than standard 960 (devices and browsers) */

// @media only screen and (max-width: 959px) {}

// /* Tablet Portrait size to standard 960 (devices and browsers) */

// @media only screen and (min-width: 768px) and (max-width: 959px) {}

// /* All Mobile Sizes (devices and browser) */

// @media only screen and (max-width: 767px) {}

// /* Mobile Landscape Size to Tablet Portrait (devices and browsers) */

// @media only screen and (min-width: 480px) and (max-width: 767px) {}

// /* Mobile Portrait Size to Mobile Landscape Size (devices and browsers) */

// @media only screen and (max-width: 479px) {}

/* hi-res laptops and desktops */

@media (min-width: 1281px) {
  .wcs-form-paypal {
    min-height: 483px;
  }
}

/*RB-1973*/

.wcs-secure-lock-black {
  left: 0;
  background-position: -105px -154px;
  top: 7px;
}

.wcs-grey-line {
  border-top: 2px solid get-color("grey-2");
  position: relative;
  top: -12px;
  width: 245px;
  left: 118px;
}

.wcs-fr_CA .giftCardLinks {
  display: inline;
}

.wcs-user-login .wcs-user-login {
  background-color: get-color("ligthGrey");
  width: 445px;
}

.wcs-creat_acct,
.wcs-guest-block {
  background-color: get-color("ligthGrey");
  padding-bottom: 8px;
}

.wcs-475.wcs-columns {
  width: 475px;
}

.wcs-426.wcs-columns {
  width: 426px !important;
}

.wcs-420.wcs-columns {
  width: 420px !important;
}

.wcs-border-right-grey {
  border-right: 2px solid get-color("ligthGrey");
}

.wcs-margin-left-30 {
  margin-left: 30px !important;
}

#login_submit.ui-state-valid {
  background: get-color("black");
  color: get-color("white") !important;
}

#guestCheckOut {
  color: get-color("white");
  background: get-color("red") !important;
  border: 0 !important;
}

.wcs-display {
  display: inline-block;
}

.wcs-marginBottom50 {
  margin-bottom: 50px !important;
}

.wcs-checkout-new {
  border-top: 2px solid get-color("ligthGrey");
  border-bottom: 2px solid get-color("ligthGrey");
  color: get-color("grey-2");
}

.wcs-checkout-new .image {
  width: 50px;
  height: 50px;
}

.wcs-checkout-new .image img,
.wcs-copyright .payment_Image img,
.greyLock_Image img {
  max-width: 100%;
  overflow: hidden;
}

.wcs-copyright .payment_Image {
  width: 41px;
  height: 26px;
}

.wcs-copyright .greyLock_Image {
  width: 14px;
  height: 18px;
}

.wcs-checkout-login.wcs-login-title {
  border-bottom: 0 !important;
}

.wcs-user-register-wrapper {
  width: 925px;
  background-color: get-color("ligthGrey");
}

.wcs-checkout-login .wcs-user-register {
  padding-top: 2%;
  width: 485px;
  margin: 0 auto;
  padding-bottom: 3%;
}

.wcs-checkout-login .wcs-user-register form {
  margin-left: 25px;
}

.wcs-noFloat {
  float: none !important;
}

.wcs-user-register-wrapper .wcs-close-btn {
  position: relative !important;
  float: right;
}

.wcs-checkout-login #login_submit {
  width: auto;
  margin-top: 12px;
  border: 0 !important;
}

.wcs-checkout-login #forgot_password_div #forgotPassword_submit {
  width: 90px;
  height: 33px;
}

#delivery_information .wcs-eight.wcs-columns {
  margin: 20px 0 15px 0;
}

.sr_shippingSummaryDiv {
  width: 100%;
  float: left;
}

.rox-lenstype-note-wrapper {
  margin-top: 20px;
  font-size: 12px;
  font-family: $font-regular;
  color: get-color("greyLighten2");
  margin-bottom: 15px;
  vertical-align: middle;
  position: relative;
}

.rox-note-tooltip-icon {
  width: 18px;
  height: 18px;
  background: get-color("ligthGrey");
  margin-left: 12px;
  display: inline-block;
  border-radius: 8px;
  position: relative;
  top: 2px;
  cursor: pointer;
}

.rox-lenstype-note {
  display: inline-block;
}

.rox-lens-tooltip-wrapper .icon-label {
  position: relative;
  top: -2px;
  left: -8px;
  color: get-color("black");
  font-size: 12px;
  font-weight: bold;
}

.wcs-prescription-tooltip-icon-info {
  text-align: right;
}

.rox-prescription-tooltip-wcs {
  position: absolute;
  width: 342px;
  right: 35px;
  background: get-color("ligthGrey");
  z-index: 99;
  top: -70px;
  padding: 23px 27px;
  box-sizing: border-box;
  display: none;
  cursor: default;
  transition: 0.5s;
}

.rox-lens-tooltip-wrapper {
  display: inline-block;
  position: relative;
}

.tooltip-header {
  font-size: 14px;
  color: get-color("black");
  font-family: $font-bold;
  font-weight: $font-weight-oswald;
  text-transform: uppercase;
}

.tooltip-body {
  margin-top: 16px;
  font-size: 12px;
  font-family: $font-regular;
  color: get-color("black");
}

.tooltip-options-head {
  margin-top: 17px;
}

ul.tooltip-options {
  margin-top: 16px;
}

.tooltip-options-head span {
  font-weight: bold;
}

div.rox-note-tooltip-icon:hover div.rox-prescription-tooltip-wcs {
  display: block;
}

.rox-prescription-tooltip-wcs::after {
  content: "";
  position: absolute;
  background: $sprite-desktop no-repeat -324px -358px;
  width: 11px;
  height: 15px;
  right: -11px;
  top: 72px;
}

.tooltip-options li::before {
  content: "";
  background: $sprite-desktop no-repeat -116px -490px;
  padding-left: 10px;
}

.tooltip-options li::before {
  content: "";
  background: $sprite-desktop no-repeat -116px -490px;
  padding-left: 10px;
}

.wcs-rox-cko-container .rox-lenstype-note {
  text-align: left;
  display: table-cell;
}

.wcs-rox-cko-container .wcs-prescription-tooltip-icon-info {
  display: table;
}

.wcs-rox-cko-container .rox-lens-tooltip-wrapper {
  display: table-cell;
  top: 20px;
}

.wcs-rox-cko-container .rox-lenstype-note-wrapper {
  margin-top: 10px !important;
  margin-bottom: 0 !important;
}

.wcs-rox-cko-container .rox-prescription-tooltip-wcs::after {
  top: 92px !important;
}

.wcs-checkout-login .wcs-prescription-tooltip-icon-info,
.wcs-checkout-login .wcs-prescription-label {
  display: table;
}

.wcs-checkout-login .rox-lenstype-note-wrapper {
  display: table;
  margin-top: 10px !important;
  margin-bottom: 0 !important;
}

.wcs-checkout-login .rox-prescription-tooltip-wcs {
  top: -222px !important;
}

.wcs-checkout-login .rox-prescription-tooltip-wcs::after {
  top: 224px !important;
}

.delivery-addr-exp,
.billing-addr-exp {
  font-size: 13px;
  line-height: 1.54;
}

.wcs-delivery-to,
.wcs-billing-too,
.wcs-billing-to {
  h4 {
    line-height: 1.69;
  }
}

.odr-body {
  #needHelpContent,
  #needHelpContact,
  #needhelpContactUsForm {
    line-height: 1.38;
  }
}

#D_CheckoutPayment_GiftCard_Redeem {
  line-height: 1.43;
}

.wcs-delivery-to.wcs-modified-deliveryto .delivery-addr-exp > p,
.wcs-billing-to.wcs-modified-billingto .billing-addr-exp > p {
  font-size: 13px;
}

.checkout-login #user_login_form input[type="email"],
.checkout-login #user_login_form input[type="password"],
.checkout-login #user_login_form input[type="text"] {
  display: inline-block;
  max-width: none;
  width: 290px;
  margin-bottom: 0;
}

.checkout-login #user_login_form .wcs-error-message {
  border: 0;
  background-color: transparent;
  padding: 0;
  margin: 0;
  font-family: $font-regular;
  font-size: 12px;
}

.checkout-login #user_login_form .wcs-error-message.exclamatory {
  display: none;
  padding: 0 0 0 10px;
  width: 5px;
  vertical-align: super;
}

.checkout-login #user_login_form .wcs-error-message.exclamatory.wcs-show {
  display: inline-block !important;
  font-size: 21px;
  font-family: $font-bold;
  font-weight: $font-weight-oswald;
}

.checkout-login #user_login_form .wcs-row {
  position: relative;
}

.checkout-login #user_login_form .wcs-row span.exclamatory {
  position: absolute;
  right: 97px;
  top: 26px;
}

#user_login_form {
  @include rb-fieldrow-custom;
  overflow: hidden;
  margin-top: 40px;
}

.wcs-product-confirm-div #create_account_form input[type="email"],
.wcs-product-confirm-div #create_account_form input[type="password"] {
  display: inline-block;
  box-sizing: border-box;
  height: 34px;
  margin-bottom: 0 !important;
}

.wcs-product-confirm-div #create_account_form .wcs-error-message {
  border: 0;
  background-color: transparent;
  padding: 0;
  margin: 0;
  font-family: $font-regular;
}

.wcs-product-confirm-div #create_account_form div.wcs-error-message,
.wcs-product-confirm-div #create_account_form span.wcs-error-message.wcs-width-312 {
  font-size: 12px;
}

.wcs-product-confirm-div #create_account_form .wcs-error-message.exclamatory {
  display: none;
  padding: 0 0 0 10px;
  width: 5px;
  vertical-align: super;
  top: 10px;
  right: 0;
  color: get-color("red");
}

.wcs-product-confirm-div #create_account_form .wcs-error-message.exclamatory.wcs-inline-block {
  display: inline-block !important;
}

.wcs-product-confirm-div #user_register_form input[type="email"],
.wcs-product-confirm-div #user_register_form input[type="password"],
.wcs-product-confirm-div #user_register_form input[type="text"] {
  display: inline-block;
  box-sizing: border-box;
  height: 34px;
  margin-bottom: 0 !important;
}

.wcs-product-confirm-div #user_register_form .wcs-error-message {
  border: 0;
  background-color: transparent;
  padding: 0;
  margin: 0;
}

.wcs-product-confirm-div #user_register_form .wcs-error-message.exclamatory {
  display: none;
  padding: 0 0 0 10px;
  width: 5px;
  vertical-align: super;
  top: 10px;
  right: 0;
  color: get-color("red");
}

.wcs-product-confirm-div #user_register_form .wcs-error-message.exclamatory.wcs-inline-block {
  display: inline-block !important;
}

.checkout-login #forgot_password_form input[type="email"] {
  display: inline-block;
}

.checkout-login #forgot_password_form .wcs-error-message {
  border: 0;
  background-color: transparent;
  padding: 0;
  margin: 0;
}

.checkout-login #forgot_password_form .wcs-error-message.exclamatory {
  display: none;
  padding: 0 0 0 10px;
  width: 5px;
  vertical-align: super;
}

.checkout-login #forgot_password_form .wcs-error-message.exclamatory.wcs-show {
  display: inline-block !important;
}

#payment_mode_form .gift_card_form .wcs-error-message.exclamatory.wcs-show {
  display: inline-block !important;
}

.wcs-register-with-us-password,
.wcs-register-with-us-confirmpassword {
  margin-bottom: 10px;
}

.payment-mode.wcs-secured-paymentpayment-mode img {
  padding-right: 9px;
}

.icon-afterpay-payment {
  background-image: url(/RayBanStorefrontAssetStore/desktop/assets/images/logo_afterpay_2020.svg) !important;
  background-repeat: no-repeat;
  background-position-y: 2px;
  background-size: auto !important;
  height: 25px;
  float: left;
  width: 100px;
  margin: 5px 4px;
  background-color: #b2fce4;
  border-style: solid;
  border-radius: 20px;
  border-color: transparent;
  border-width: 5px 9px;
}

.icon-payment-mode-afterpay {
  width: 100%;
  padding-top: 6px;
}

.wcs-installment-form .installment-message {
  font-family: $font-regular;
  font-size: 12px;
}

#noOfInstallmentsBR.wcs-default-dropdown {
  margin-bottom: 36px;
}

.wcs-product-confirm-div #create_account_form .wcs-error-message {
  font-family: $font-regular;
}

#payment_mode_creditCard p.wcs-line-height-18.wcs-add-bottom {
  margin-top: 6px !important;
}
#chkout_summary .gift-card-total-label {
  text-align: left;
}

#chkout_summary .shoping-items.gift-card-total {
  margin: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 0 !important;
  margin-bottom: 16px;
}

#chkout_summary .gift-card-total .wcs-delivery-cost {
  text-transform: uppercase;

  .wcs-remove {
    margin-left: 10px;
    text-transform: capitalize;
    color: $primary-color;
  }
}

#chkout_summary .gift-card-total .gift-card-total-label span:first-child {
  text-align: left;
  border-width: 1px 0 1px 0;
  border-style: solid;
  border-color: #e2e2e5;
  height: 53px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  color: #007d17;
  margin-right: 0;
  width: auto;
}

#chkout_summary .gift-card-total .gift-card-total-price span:first-child {
  text-align: right;
  border-width: 1px 0 1px 0;
  border-style: solid;
  border-color: #e2e2e5;
  height: 53px;
  margin-bottom: 16px;
  margin-right: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #007d17;
}

#chkout_summary .gift-card-total .gift-card-total-text {
  margin: 0;
  font-size: 16px;
  text-transform: uppercase;
  background: none;
  font-family: $font-bold;
  font-weight: $font-weight-oswald;
  word-break: normal;
}

#chkout_summary .gift-card-total-label .gift-card-total-text {
  text-align: left;
}

.return-summary .wcs-delivery-total-cost,
.return-payment .wcs-delivery-total-cost {
  margin: 0 !important;
}

.return-summary .wcs-delivery-subtotal,
.return-payment .wcs-delivery-subtotal {
  margin: 10px 0 0 0 !important;
}

.return-summary .shoping-items .wcs-delivery-subtotal {
  margin-top: 5px !important;
}

.return-summary .wcs-shopping-scroller,
.return-payment .wcs-shopping-scroller {
  width: 267px !important;
}

.return-payment .wcs-shopping-total-container {
  width: 267px !important;
}

.return-payment .wcs-delivery-total-text {
  margin-left: 0 !important;
}

#user_register_form fieldset {
  border-top: 1px solid get-color("ligthGrey");
  margin-bottom: 0;
  position: relative;
}

#user_register_form fieldset legend {
  padding-right: 9px;
  padding-bottom: 0;
  font-size: 14px;
}
#user_register_form .privacy-policy-fieldset {
  width: 100%;
  border: solid 2px $login-border-color;
}

#user_register_form {
  margin-top: 40px;
}

#user_register_form {
  @include rb-fieldrow-custom;
  .wcs-dob-select {
    margin-right: 20px;
    &:last-child {
      margin-right: 0;
    }
  }
  .rb-red-fg {
    .rb-fieldrow__helper--error {
      display: block;
      & + .rb-fieldrow__helper {
        display: none;
      }
    }
    .rb-select-wrapper select {
      color: $darker-red;
      border-color: $darker-red;
    }
  }
  .wcs-user-register-checkbox-container {
    margin-top: 41px;
  }
  .wcs-label-check {
    padding-left: 40px;
    padding-top: 0;
    margin-bottom: 26px;

    span {
      color: $primary-color;
    }

    .rb-fieldrow-checkbox__title {
      font-family: $font-bold;
      font-weight: $font-weight-oswald;
      font-size: 14px;
    }

    .rb-fieldrow-checkbox__checkmark {
      top: 0;
    }
  }
  .wcs-label-check.rb-invalidrow.rb-fieldrow--required,
  .rb-fieldrow-checkbox.rb-invalidrow.rb-fieldrow--required {
    .rb-fieldrow-checkbox__checkmark {
      border-color: $darker-red;
    }
    .rb-fieldrow__helper--error {
      display: block;
      color: $darker-red;
    }
  }
  .wcs-label-check.rb-invalidrow.rb-fieldrow--required.wcs-c-on {
    .rb-fieldrow-checkbox__checkmark {
      border-color: #cfcfcf;
    }
    .rb-fieldrow__helper--error {
      display: none;
      color: $darker-red;
    }
  }
  .wcs-label-check a {
    text-decoration: underline;
  }
}

#form_password_reset,
.wcs-myDetails-lightBox-form {
  @include rb-fieldrow-custom;
}

#user_register_form .wcs-label-check.checkbox-invalid {
  .rb-fieldrow__helper--error {
    display: block;
    color: $darker-red;
  }
  .rb-fieldrow-checkbox__checkmark {
    border-color: $darker-red;
  }
}
#user_register_form .wcs-label-check.checkbox-invalid.wcs-c-on {
  .rb-fieldrow__helper--error {
    display: none;
  }
  .rb-fieldrow-checkbox__checkmark {
    border-color: #cfcfcf;
  }
}

.privacy-policy-fieldset {
  .privacy-policy-legend {
    margin: 0 20px;
    padding: 0 10px;
  }
  .privacy-policy-years {
    display: flex;
    padding-left: 35px;
    label.rb-fieldrow {
      margin-right: 35px;
    }
  }
}

.privacy-policy-fieldset .content-fieldset .one_year {
  float: left;
  margin-right: 30px;
  margin-left: 21px;
  margin-bottom: 10px;
}

.privacy-policy-fieldset .content-fieldset .three_year {
  float: left;
  margin-right: 30px;
  margin-bottom: 10px;
}

.privacy-policy-fieldset .content-fieldset .five_year {
  float: left;
  margin-bottom: 10px;
}

.privacy-policy-fieldset .content-fieldset .wcs-label-check.clear-left {
  clear: left;
}

.privacy-policy-fieldset .content-fieldset {
  padding: 10px 30px;
  display: block;
}

.privacy-policy-fieldset .content-fieldset input[type="radio"] {
  left: 0;
  top: 0;
}

.privacy-policy-fieldset .content-fieldset .wcs-label-check {
  margin: 10px 0;
}

.privacy-policy-fieldset input.ui-state-error ~ span.wcs-padLeft-4,
.privacy-policy-fieldset input.ui-state-error ~ span.wcs-padLeft-4 a {
  color: get-color("red") !important;
}

.privacy-policy-fieldset .ui-state-error + span.wcs-checkbox {
  background-position: -55px -46px;
}

.afterpay-content .afterpay-overlay-link span:first-child.wcs-afterpay-text {
  width: auto;
  text-transform: lowercase;
}

.afterpay-content .afterpay-overlay-link span:first-child.wcs-afterpay-less-or-more-amount-text {
  width: 100% !important;
  font-size: 10.5px;
  text-align: left;
}

.my-details-shipping input[type="submit"] {
  color: get-color("white");
}

.wcs-ko_KR {
  .wcs-return-delivery-button-div {
    .wcs-return-delivery-button-left,
    .wcs-return-delivery-button-right {
      width: 100%;
    }
  }
}

// Checkbox Group
.checkbox-group {
  .exclamatory {
    display: none;
  }

  &.edit-add-address {
    float: none;
  }
}

.wcs-pt_BR input.rb-exp-date {
  color: get-color("grey-3");
}

/*** Payment Page - bugfix/RB-28542-UI ***/
.wcs-pt_BR {
  #payment_mode_form {
    .wcs-width-440 {
      width: 440px !important;
    }
  }

  // Hide Discover logo for Brazil store
  .payment-mode-icons {
    img[alt="discover"] {
      display: none;
    }
  }
}

// Klarna API styles

.klarna-row-dob {
  margin-bottom: 25px;
  margin-top: 25px;
}

.klarna-dob-select {
  width: 33%;
  float: left;
  padding-right: 15px;
}

.klarna_payment_options {
  width: 560px;
  .tabs {
    input[type=radio] {
      display: block;
      position: absolute;
      margin-top: 0;
      top: 16px;
      left: 8px;
    }
  }
   
}

.tabs {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  padding: 20px;
}

.container {
  flex-basis: 100%;
  position: relative;
}

.tabs {
  input[type="radio"] {
    display: none;
    position: absolute;
    margin-top: 20px;
  }
}

.dot {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 10px;
  bottom: 0;
  margin: auto;
  border-radius: 100%;
  display: block;
  width: 10px;
  height: 10px;
  border: 1px solid $grey-2;
}

.tabs {
  input[type="radio"] {
    ~ .tab-content {
      display: none;
    }
  }
}

.tabs {
  input[type="radio"]:checked {
    ~ .tab-content {
      display: block;
    }
  }
}

.tabs {
  input[type="radio"]:checked {
    + .tab {
      .dot {
        background: $black;
        width: 10px;
        height: 10px;
      }
    }
  }
}

.tabs {
  label {
    transition: 0.3s ease-in-out all;
    padding: 1em 0 1em 3em;
    display: block;
    cursor: pointer;
    position: relative;
    border-top: 1px solid $grey-4;
  }
}

.tab-content {
  width: calc(100% - 2em);
  padding: 1em;
}

.klarna-image {
  width: 56px;
  height: 32px;
  position: absolute;
  right: 14px;
  top: 8px;
}

.wcs-afterpay-icon-checkout {
  width: 18px;
  height: 18px;
  background: $grey-6;
  display: inline-block;
  border-radius: 8px;
  position: relative;
  top: 4px;
  cursor: pointer;
  text-align: center;
}

.wcs-afterpay-icon-label {
  font-weight: bold;
  width: 18px !important;
  height: 18px !important;
  margin-left: 0;
  position: relative;
  top: 0;
  left: -7px;
  text-transform: lowercase;
  vertical-align: middle;
}

.submitForgotContainer {
  width: 200px;
  float: right;
  div {
    float: right;
  }
  button {
    float: right;
    margin-top: 25px;
  }
}

#forgot_password_form {
  padding-top: 0;
}

.wcs-user-register {
  .rb-fieldrow-checkbox__desc {
    font-size: 12px;
    line-height: 19px;
  }
  button {
    float: right;
    margin-top: 40px;
  }
}

#forgot_password_div {
  @include rb-fieldrow-custom;
}

.au-privacy-policy {
  color: #1f1f24;
  margin-top: -20px;
  margin-bottom: 20px;
  a {
    text-decoration: underline;
    text-transform: capitalize;
    color: #666;
  }
}

.rb-red-fg {
  .wcs-error-message {
    display: block;
    color: get-color("red");
  }

  .wcs-password-text {
    display: none;
  }
}

.thankyou_agree {
  display: inline-block;
}

.agree-checkbox {
  .wcs-label-check {
    padding-left: 37px;
  }
  .wcs-c-on {
    .wcs-checkbox {
      background: none;
      background-color: #1f1f24 !important;
      height: 20px;
      width: 20px;
      border: 1px solid #cfcfcf;
    }

    .wcs-checkbox:after {
      content: "";
      position: absolute;
      display: block;
      left: 5px;
      top: 1px;
      width: 8px;
      height: 12px;
      border: solid #fff;
      border-width: 0 1px 1px 0;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}
.wcs-delivery-return-form-container {
  width: 100%;
  max-width: 462px;
}

.wcs-delivery-information--return {
  .rb-fieldrow input[required] ~ label:after {
    content: none !important;
  }

  .wcs-sbm-button {
    float: right;
  }

  .rb-select-wrapper .ui-state-valid,
  .rb-floating input {
    background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24'%3E%3Cpath fill='%23007D17' d='M10.291 17.63l-5.28-5.053 1.76-1.685 3.52 3.37 7.92-7.581 1.759 1.684z'/%3E%3C/svg%3E");
    background-size: 20px;
    background-repeat: no-repeat;
    background-position: 100%;
    background-position-y: center;
  }

  .rb-invalidrow input {
    background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24'%3E%3Cpath fill='%23C20E1A' d='M12 4c-4.4 0-8 3.6-8 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm.8 12h-1.6v-1.6h1.6V16zm0-3.2h-1.6V8h1.6v4.8z'/%3E%3C/svg%3E");
    background-size: 20px;
    background-repeat: no-repeat;
    background-position: 100%;
    background-position-y: center;
  }

  .rb-fieldrow-select {
    .ui-state-valid {
      background-position: calc(100% - 25px);
    }
    label {
      font-size: 75%;
      opacity: 1;
      color: #666;
    }
  }
  .rb-invalidrow {
    label {
      color: $darker-red !important;
    }

    .rb-fieldrow__helper {
      display: none !important;
    }

    .rb-fieldrow__helper--error {
      display: block !important;
    }
  }
}

.rb-select-dob {
  width: 100% !important;
  display: flex;

  .rb-select-wrapper {
    margin-right: 20px;
    width: 140px;
  }

  .rb-select-ddmmyyyy-day.rb-select-wrapper,
  .rb-select-ddmmyyyy-month.rb-select-wrapper {
    &:after {
      right: 15px !important;
    }
  }

  .day-value,
  .year-value,
  .month-value {
    padding-bottom: 7px;
    border-bottom: 1px solid black;
  }

  .label-option {
    color: $grey-2;
  }

  .day-dropdown,
  .year-dropdown,
  .month-dropdown {
    position: absolute;
    border: 1px solid black;
    background: white;
    width: 140px;
    z-index: 2;

    .js-day-option:hover,
    .js-year-option:hover,
    .js-month-option:hover {
      background-color: #87cefa;
      color: white;
    }
  }

  .day-dropdown,
  .year-dropdown {
    height: 210px;
    overflow-y: scroll;
  }

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }

  .year-dropdown {
    width: 100%;
  }

  .rb-select-wrapper:before {
    right: -5px !important;
  }

  .rb-select-ddmmyyyy-year:after {
    right: 15px !important;
  }

  .rb-select-ddmmyyyy {
    &-day,
    &-month,
    &-year {
      &.rb-invalidrow {
        border: 0 !important;
      }
    }
  }
}

.wcs-user-register {
  .rb-select-ddmmyyyy {
    .rb-fieldrow__helper {
      display: block !important;
      color: black !important;
    }
  }

  .rb-fieldrow__helper {
    margin-top: 8px !important;
  }
}

.wcs-user-register,
#wcs-account-edit-form {
  .rb-select-ddmmyyyy {
    display: inline-block;
    width: 100%;
  }

  .rb-select-dob .rb-fieldrow__helper {
    display: block;
  }

  .rb-invalidrow {
    .rb-fieldrow__helper--error,
    label {
      color: #c20e1a !important;
    }

    .day-value,
    .year-value,
    .month-value {
      border-bottom: 1px solid #c20e1a;
    }
  }

  .rb-validrow {
    label,
    .rb-fieldrow__helper {
      color: $greyLighten2 !important;
    }
  }
}

#klarna_payment_info {
  .klarna-error {
    display: none;
    color: $darker-red !important;
  }
  .klarna-error-msg {
    color: $darker-red !important;
  }

  .rb-red-fg {
    .klarna-error {
      display: block !important;
      color: $darker-red;
      font-size: 12px;
    }
  }
}

// html {
//   overflow-y: initial;
// }
// body {
//   overflow-y: initial;
//   overflow-x: initial;
// }

.fb-login-btn-holder {
  display: flex;
  flex-direction: column;
  align-items: center;

  &.registration-page {
    padding: 16px 0 16px 0;
    align-items: center;
  }

  .fb-custom-btn {
    background: #1877f2;
    min-width: 209px;
    padding: 10px 20px;
    transition: opacity 0.3s linear;
    margin: 0;

    &:hover {
      opacity: 0.5;
    }

    span {
      color: $white;
      font-size: 14px;
      line-height: 20px;
      text-transform: uppercase;
      font-family: $font-bold;
      font-weight: 500;
      padding-left: 42px;
      position: relative;
      display: inline-block;

      svg {
        width: 40px;
        height: 40px;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(-10px, -50%);
        z-index: 2;
      }
    }
  }

  > span {
    text-transform: capitalize;
    display: block;
    margin-bottom: 15px;
    font-size: 14px;
  }

  &.fb-delivery-page {
    margin-bottom: 0;

    > span {
      margin-top: 15px;
    }
  }
}

.existing-user-message {
  .redirect-link {
    display: inline-block;
    margin: 0 5px;
    text-decoration: underline;
    &:hover {
      cursor: pointer;
      text-decoration: none;
    }
  }
}

.custom-select-holder {
  &.hidden {
    display: none !important;
  }
}

.reverse-row-jp {
  display: flex;
  flex-direction: column-reverse;
}

.dob-month {
  margin-left: 20px;
}

.month-margin {
  margin-left: 0;
}

.wcs-forgot-password {
  .wcs-login-title,
  .wcs-user-register {
    max-width: 460px;
    margin: 0 auto;

    .wcs-note-red,
    .wcs-note-green {
      margin-bottom: 107%;
    }
  }
}


.rb-fieldrow {
  input,
  textarea {
    & + label {
      position: absolute;
      top: 20px;
      left: 0;
      transition: all 0.2s;
      color: #1f1f24;
      transform: translateY(50%);
      text-transform: lowercase;
      font-size: 16px;
    }

    &:not(:placeholder-shown),
    &:not(:-ms-input-placeholder),
    &:focus {
      & + label {
        font-size: 75%;
        opacity: 1;
        color: #666;
        transform: translate3d(0, -100%, 0);
      }
    }

    &:not(:placeholder-shown) ~ label {
      font-size: 75%;
      transform: translate3d(0, -100%, 0);
      opacity: 1;
    }

    &:not(::-ms-input-placeholder) ~ label {
      font-size: 75%;
      opacity: 1;
      color: $primary-color;
      transform: translateY(-100%);
    }

    &:not(:-ms-input-placeholder) ~ label {
      font-size: 75%;
      opacity: 1;
      color: $primary-color;
      transform: translateY(-100%);
    }
  }

  input:-webkit-autofill {
    & ~ label {
      font-size: 75%;
      transform: translate3d(0, -100%, 0);
      opacity: 1;
    }
  }

  .rb-fieldrow__helper--error {
    display: none;
  }
}

.wcs-delivery-total {
  .rb-pay-over-time__button {
    margin-top: 8px;
    font-size: 10px !important;
    .common__icon--paypal {
      width: 50px !important;
    }
    .common__icon--afterpay-logo {
      width: 50px !important;
    }
    span {
      width: auto;
      word-break: unset;
    }

    .rb-pay-over-time__message {
      font-size: 11px;
    }
  }
  .rb-modal-close .common__icon--close-bold {
    text-indent: 20px;
  }
}